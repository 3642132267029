/**
  Providing simple sane defaults
  for some HTML elements.

  Most styling should be done on a per-component basis,
  and this isn't meant to be a usable CSS library.
*/

* {
   -webkit-font-smoothing: antialiased;
   box-sizing: border-box;
}

html {
   height: 100%;
   font-family: CiscoSans, Helvetica, Arial, "Lucida Grande", sans-serif;
   /** 10px font size is for backwards compatibility for app teams using "REM" for sizing */
   font-size: 10px !important;
}

body {
   height: 100%;
   overflow: hidden;
   background-color: var(--body-background, #ffffff);
   font-size: 14px;
   line-height: 1.42857143;
   color: var(--secondary-body-text, #535b62);

   --platform-banner-height: 0px;
   --platform-header-height: 56px;
   --platform-top-shift: calc(
      var(--platform-banner-height) + var(--platform-header-height)
   );
   --hbr-app-shift-top: calc(
      var(--platform-banner-height) + var(--platform-header-height)
   );

}

h1 {
   color: var(--header-2-color-primary, #535b62);
   margin: 12px 0;
   font-size: 24px;
   font-weight: 300;
   line-height: 30px;
   letter-spacing: 0.5px;
}

h2 {
   color: var(--header-2-color-primary, #535b62);
   margin: 12px 0;
   font-weight: 300;
   font-size: 18px;
   letter-spacing: 0.75px;
   line-height: 26px;
}

h3 {
   color: var(--header-2-color-primary, #535b62);
   font-weight: 300;
   font-size: 18px;
   line-height: 24px;
   margin-top: 20px;
   margin-bottom: 10px;
}

h4 {
   color: var(--header-2-color-primary, #535b62);
   font-weight: 400;
   font-size: 14px;
   line-height: 20px;
   margin-top: 36px;
   text-transform: uppercase;
   letter-spacing: 1px;
}

h5,
h6 {
   color: var(--header-2-color-primary, #535b62);
   font-size: 12px;
   font-weight: 100;
   line-height: 1.1;
}

a {
   cursor: pointer;
   text-transform: none;
   font-weight: 400;
   letter-spacing: 0;
   color: var(--link-normal, #1170cf);
   text-decoration: none;
}

a:hover {
   color: var(--link-hover, #0353a8);
}

b {
   font-weight: 700;
}

strong {
   font-weight: 700;
}

/**
  We shouldn't have this many "main containers" ,
  But in order to remove some work needs to be done in shell,
  which will probably come next --

  In the meantime this is a temporary hack so the entire frontend
  doesn't implode apart on the VM environment
*/
#__defaultPluginBody,
#main-content,
.dnacp-root-container,
.page-main {
   height: 100%;
   overflow: hidden;
}

.dnacp-root-container {
   display: flex;
   flex-direction: column;
}
