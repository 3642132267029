/**
  Some of dnx component styling relied on some base bootstrap styles that were removed in P3.0.
  This causes some inconsistencies with previous versions of platform

  Until the css gets resolved in the components repo, we'll fix them ourselves in this file
*/

/**
  Tracker: https://github3.cisco.com/dna-platform-ui/dnx-web-components/issues/3113
*/
dnx-button button {
  border: none;
  cursor: pointer;
}

/**
  Tracker: https://github3.cisco.com/dna-platform-ui/dnx-web-components/issues/3125
*/
dnx-product-level-alert .hidden {
  display: none !important;
}

/**
  https://github3.cisco.com/dna-platform-ui/dnx-web-components/commit/41134bedc4d7a50c94ef01dd88a20f2f1357926f
  # this is the temp dnx table css patch due to bootstrap deprecation
  # TODO: to be removed when topology resovle the confliction with DNX staging
*/
.dnx-dtable.dnx-table .dataTables_wrapper .dt-tooltop-container .dt-buttons.dt-filter-btn-container .dt-filter-btn span {
  display: flex;
}
.dnx-dtable.dnx-table .dataTables_wrapper .dt-tooltop-container .dt-tooltop-find.__dt-tooltop-find label::before {
  line-height: 1.42857143;
}
.dnx-dtable.dnx-table .dataTables_wrapper .dt-tooltop-container .dt-tooltop-find.__dt-tooltop-find label input {
  margin-left: 0.5em;
}
