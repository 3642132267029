/**
  Historically, in a pre component universe,
  One way to make reusable elements was to expose global "utility classes" for others to use.

  These classes were always undocumented, hard to trace, and maintenance nightmares.

  Luckily, most of these classes have faded off into existence,
  but some of them were copy and pasted enough they escaped the claws of tech debt cleanup and are still around today.

  So for those lucky few survivors, we'll continue providing the backwards support,
  and one day they too will vanish in a new release
*/


/**
  These were used in jQuery based codebases to toggle the visibility of an element
  without having to do an expensive re-initialize each time.
*/
.show { display: block; }
.hide { display: none; }


/**
  Covers an entire element with a slightly transparent overlay
  and renders a loading spinner in the middle of it.

  Using a large loader like this should be pretty discouraged
  as it makes the app feel slow
*/
.global-load {
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--body-background,#fff);
  opacity: .9;
  z-index: var(--zindex-global-loader);
  display: flex;
  align-items: center;
  justify-content: center;
}

.global-load .global-load-container > div {
  margin-bottom: 5px;
}

.global-load .load-text {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: .6px;
  color: var(--secondary-body-text,#535b62);
  font-weight: 300;
}


/**
  The call to action bar, or CTA, positions a couple action buttons to the bottom of a scrollable container.

  Mainly used in sidebars and table pages, these classes are a prime candidate for a component
  but for some reason were settled on using css.
*/

.cta-container {
  position: relative;
  height: 100%;
}

.cta-container .cta-content {
  height: 100%;
  overflow-y: auto;
}

.cta-container .cta-content:after {
  display: block;
  padding-bottom: 82px;
  content: "";
}

.cta-bar {
  padding: 24px 32px;
  text-align: right;
  font-size: 0;
  background-color: var(--cta-background,#fff);
  border: var(--cta-border,none);
}

.cta-container .cta-bar {
  box-shadow: var(--cta-shadow,0 -2px 4px 0 hsla(0,0%,75.3%,.5));
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 24px 32px;
  text-align: right;
  font-size: 0;
  background-color: var(--cta-background,#fff);
  border: var(--cta-border,none);
}